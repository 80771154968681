@import "@/styles/variables.scss";

.userLanguageSelect {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: $spacing-4;

  &_variants {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: $spacing-3;
    width: 100%;
  }
}
