@import "@/styles/variables.scss";

.userProfileInfo {
  width: 100%;

  &_item {
    width: 100%;
    padding: $spacing-6 0;
    border-bottom: 1px solid $color-gray;
    display: flex;
    flex-direction: column;
    gap: $spacing-2;

    &__isDeactive {
      opacity: 0.5;

      .userProfileInfo_itemAction:disabled,
      .userProfileInfo_itemAction[disabled] {
        color: $color-gray-dark;
        cursor: not-allowed;
      }
    }
  }

  &_itemTopBar {
    display: flex;
    align-items: flex-start;
    gap: $spacing-5;
  }

  &_itemTitle {
    margin-right: auto;
  }

  &_itemAction {
    border: none;
    background-color: unset;
    padding: $spacing-1 $spacing-2;
    color: $color-green-main;
    cursor: pointer;
    text-decoration: underline;
  }

  &_itemValue {
    color: $color-gray-dark;
    font-size: $font-size-s;
  }

  &_editContainer {
    margin-top: $spacing-4;
  }

  &_birthdayForm {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: $spacing-4;
  }

  &_birthdayFields {
    display: flex;
    gap: $spacing-3;
  }

  &_birthdaySelect {
    width: 100px;
  }

  &_birthdayInput {
    display: flex;
    flex-direction: column;
    gap: $spacing-3;
  }

  &_birthdayLabel {
    color: $color-gray-dark;
    font-size: $font-size-s;
  }
}
