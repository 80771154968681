@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.listingFilter {
  &_showDropdown {
    position: relative;
    display: flex;
    //align-items: center;
    gap: $spacing-2;
    padding: $spacing-1 $spacing-4;
    z-index: $z-index-dropdown-button-active;
    border: $border-width-base solid $color-gray;
    border-radius: $border-radius-base;
    font-size: $font-size-s;
    text-wrap: nowrap;
    width: max-content;
    color: $color-black;
    background-color: #fff;
    cursor: pointer;
    line-height: 22px;

    &:hover {
      border-color: $color-black;
    }

    &:disabled {
      cursor: default;
      color: $color-gray-dark;
    }
  }

  &_popover {
    position: relative;
    min-width: 180px;
    background-color: #fff;
    border: $border-width-base solid $color-black;
    box-shadow: $shadow;
    border-radius: $border-radius-base;

    &[data-entering] {
      animation: popover-slide 150ms;
    }

    &[data-exiting] {
      animation: popover-slide 150ms reverse ease-in;
    }
  }

  &_top {
    border-bottom: $border-width-base solid $color-gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-3;
  }

  &_title {
    font-size: $font-size-l;
    font-weight: 500;
    padding: $spacing-4;
  }

  &_closeButton {
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    align-items: center;
    font-size: 20px;
    color: $color-gray-dark;
    cursor: pointer;
    line-height: 0;
    background: none;
    border: none;

    &:hover {
      color: $color-black;
    }
  }

  &_children {
    padding: $spacing-5;
  }
}

@include for-screen-from-768 {
  .listingFilter {
    &_showDropdown {
      padding: $spacing-3 $spacing-6;
      font-size: $font-size-base;
    }
  }
}

@keyframes popover-slide {
  from {
    transform: var(--origin);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
