@import "@/styles/variables.scss";

.addressCheckSupport {
  display: flex;
  gap: $spacing-1;
  align-items: baseline;

  &_supportButton {
    color: $color-green-main;
    font-weight: 500;
    cursor: pointer;
  }
}
