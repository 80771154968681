@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.blogCard {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border: 1px solid $color-gray;
  border-radius: $border-radius-base;
  overflow: hidden;

  &__list {
    flex-direction: row;

    .blogCard_link {
      flex-direction: row;
    }

    .blogCard_body {
      padding: $spacing-2;
      width: 100%;
    }

    .blogCard_title {
      font-size: $font-size-s;
      font-weight: 500;
      margin-top: 0;
    }

    .blogCard_description {
      display: none;
    }

    .blogCard_imageContainer {
      min-height: unset;
      max-height: unset;
      min-width: 60px;
    }

    .blogCard_action {
      margin-top: $spacing-1;
      margin-right: auto;
      width: auto;
    }
  }

  &_skeleton {
    width: 100%;
    height: 100%;
  }

  &_link {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: inherit;
    text-decoration: none;

    &:hover,
    &:visited {
      color: inherit;
      text-decoration: none;
    }
  }

  &_imageContainer {
    position: relative;
    overflow: hidden;
    max-height: 236px;
    height: 100%;
    min-height: 279px;
  }

  &_image {
    object-fit: cover;
    object-position: center;
  }

  &_body {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: $spacing-4;
  }

  &_content {
    margin-bottom: 6px;
  }

  &_title {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
  }

  &_description {
    margin-top: 14px;
    font-size: $font-size-s;
    line-height: 20px;
  }

  &_action {
    margin-top: auto;
    width: 130px;
  }
}

//@include for-screen-from-1024 {
//  .blogCard {
//    width: 310px;
//  }
//}
//
//@include for-screen-from-1200 {
//  .blogCard {
//    width: 372px;
//  }
//}


