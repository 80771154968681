@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.listingViewSelect {
  &_dropdownIcon {
    margin-top: 4px;
  }

  &_options {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }

  &_optionItem {
    width: 100%;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: $spacing-3;
    font-size: 16px;

    &[data-disabled] {
      color: $color-green-main;
    }

    &[data-hovered]:not(&[data-disabled]) {
      cursor: pointer;
    }
  }

  &_optionIcon {
    font-size: 22px;
  }
}

@include for-screen-from-768 {
  .listingViewSelect {
    &_dropdownIcon {
      margin-top: 2px;
    }
  }
}
