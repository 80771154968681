@import "@/styles/variables";

.inputText {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  width: 100%;
  position: relative;

  &_input {
    background-color: unset;
    border: 1px solid $color-gray;
    border-radius: $border-radius-base;
    padding: 18px;
    font-size: $font-size-base;

    &:focus {
      border-color: $color-green-main;
      box-shadow: 0 0 6px 2px $color-green-main;
    }

    &:disabled,
    &[disabled]  {
      border-color: $color-gray;
      background-color: $color-gray-light;
      color: hsl(0, 0%, 60%);
      pointer-events: none;
    }
  }

  &__isError {
    .inputText_input {
      border-color: $color-red;

      &:focus {
        border-color: $color-red;
        box-shadow: 0 0 6px 2px $color-red;
      }
    }
  }

  &_error {
    position: absolute;
    bottom: -22px;
    font-size: $font-size-s;
    color: $color-red;
  }
}
