@import '@/styles/variables.scss';

.mapViewSwitch {
  padding: $spacing-3 $spacing-7;
  background-color: $color-black;
  border-radius: 16px;
  border: 1px solid $color-gray;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.45);
  line-height: 0;

  &_mapIcon {
    color: #fff;
    line-height: 0;
    font-size: 19px;
  }
}
