@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.map {
  flex: 1;
  display: none;
  top: 80px;
  bottom: 0;
  width: 100%;
  height: calc(100vh - 80px);
  align-items: center;
  justify-content: center;
  background-color: $color-gray;
  box-shadow: $shadow;
  border-radius: $border-radius-base;

  &__isActive {
    display: flex;
  }

  @include for-screen-from-1024 {
    display: flex;
    position: sticky;
  }
}
