@import '@/styles/variables.scss';

.filterToggle:not(:last-child) {
  margin-bottom: $spacing-2;
}

.filterToggle {
  display: block;
  width: 100%;
  background-color: transparent;
  border: 1px solid $color-gray;
  border-radius: $border-radius-base;
  font-size: $font-size-base;
  text-align: left;
  padding: $spacing-3 $spacing-4;
  cursor: pointer;

  &:not([data-disabled]):hover {
    border-color: $color-black;
  }

  &[data-disabled] {
    color: #fff;
    background-color: $color-green-main;
    border-color: $color-green-main;
    cursor: not-allowed;
  }
}
