@import "@/styles/variables.scss";

.aboutForm {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: $spacing-4;

  &_text {
    min-height: 130px;
    width: 100%;
    padding: $spacing-4;
    border-radius: $border-radius-base;
    border: 1px solid $color-gray;
    font-size: $font-size-s;
    font-family: inherit;
    resize: none;

    &:focus {
      border-color: $color-green-main;
      box-shadow: 0 0 6px 2px #417054;
    }

    &::placeholder {
      color: $color-gray-dark;
      font-size: $font-size-s;
    }
  }

  &_bottom {
    display: flex;
    width: 100%;
    gap: $spacing-4;
    justify-content: space-between;
  }

  &_symbolsCount {
    color: $color-gray-dark;

    &__error {
      color: $color-red;
    }
  }
}

