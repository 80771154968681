@import "@/styles/variables";

.topReviewsPage {
  &_title {
    font-size: $font-size-xxxl;
    font-weight: 500;
    text-align: center;
    margin-top: $spacing-8;
  }

  &_reviewsList {
    margin-top: $spacing-8;
    display: flex;
    flex-direction: column;
    gap: $spacing-5;
  }
}
