@import "@/styles/variables";
@import "@/styles/mixins";

.listingDetailed {
  display: flex;
  flex-direction: column;

  &_body {
    padding-top: $spacing-6;
    padding-bottom: $spacing-6;
    display: grid;
    flex-wrap: wrap;
    gap: $spacing-6;
    grid-template-areas:
      "gallery gallery"
      "title title"
      "content content";
    grid-template-columns: 1.8fr 1.2fr;
  }

  &_title {
    grid-area: title;
    font-size: $font-size-xl;
    font-weight: 500;
    max-width: 520px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacing-3;
    padding-right: $spacing-3;
  }

  &_gallery {
    grid-area: gallery;
  }

  &_content {
    grid-area: content;
    display: flex;
    flex-direction: column;
    max-width: 520px;
    gap: $spacing-6;
    margin-left: auto;
    margin-right: auto;
  }

  &_box {
    padding: $spacing-5 $spacing-3;
    border: 1px solid $color-gray;
    border-radius: $border-radius-base;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
  }

  // Info
  &_info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing-5;
  }

  &_infoTitle {
    color: $color-gray-dark;
    margin-bottom: $spacing-1;
  }

  // Geo
  &_geoAddress {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-6;
    color: $color-green-main;

    p {
      text-decoration: underline;
    }
  }

  &_addressIcon {
    font-size: $font-size-xxl;
    margin-right: $spacing-2;
  }

  // Description
  &_descriptionTitle {
    font-size: $font-size-l;
    font-weight: 500;
    margin-bottom: $spacing-4;
  }

  &_descriptionText {
    line-height: $font-size-xxl;
    font-size: 15px;
  }

  &_sidebar {
    display: none;
  }

  &_user {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: $spacing-6;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
  }

  &_listings {
    position: relative;
    grid-area: listing;
  }

  &_listingsTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-6;
  }

  &_listingsButton {
    position: absolute;
    bottom: $spacing-5;
    right: 50%;
    transform: translateX(50%);
    max-width: 220px;
    width: 100%;
    z-index: 10;
  }

  &_listingsList {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    grid-template-rows: 1fr 40px 0 0;
    gap: $spacing-4;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      height: 70px;
      bottom: 0;
      left: 0;
      z-index: 6;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgb(255, 255, 255) 100%);
    }
  }

  &_listingCard {
    position: relative;
    min-width: 290px;
    width: 100%;
    display: flex;
  }

  &_mobileBar {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    gap: $spacing-4;
    width: 100%;
    z-index: $z-index-mobile-listing-bar;
    background-color: #fff;
    padding: $spacing-5 $spacing-4;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 16px 16px 22px;
  }

  &_messageButton {
    max-width: 280px;
    width: 100%;
    display: flex;
    margin-left: auto;
  }
}

@include for-screen-from-360 {
  .listingDetailed {
    &_title {
      padding-left: unset;
      padding-right: unset;
    }

    &_box {
      padding: $spacing-5 $spacing-5;
    }
  }
}

@include for-screen-from-480 {
  .listingDetailed {
    &_box {
      padding: $spacing-5 $spacing-6;
    }

    &_title {
      font-size: $font-size-xxl;
    }
  }
}

@include for-screen-from-768 {
  .listingDetailed {
    &_mobileBar {
      display: none;
    }

    &_sidebar {
      grid-area: sidebar;
      align-self: start;
      display: flex;
      flex-direction: column;
      gap: $spacing-6;
      position: sticky;
      top: 188px;
    }

    &_body {
      grid-template-areas:
        "gallery gallery"
        "title title"
        "content sidebar";
    }

    &_content {
      max-width: unset;
      margin-left: unset;
      margin-right: unset;
    }

    &_title {
      margin-left: unset;
      margin-right: unset;
    }

    &_listingsList {
      grid-template-columns: 1fr 1fr 60px 0;
      grid-auto-flow: column;
      grid-template-rows: 1fr;

      &:after {
        display: none;
      }
    }

    &_listingsButton {
      position: static;
      transform: unset;
      max-width: 180px;
    }

    &_listingCard__withFade::before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 6;
      background: linear-gradient(to right, rgba(255, 255, 255, 0.4), #fff 20%);
    }

    &_listingCard__isProfile {
      min-width: 380px;
    }
  }
}

@include for-screen-from-1024 {
  .listingDetailed {
    &_sidebar {
      top: 100px;
    }
  }
}
