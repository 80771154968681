@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

//box-shadow: rgba(0, 0, 0, 0.12) 0 6px 16px;

.userProfilePage {
  max-width: 620px;
  margin: $spacing-4 auto;
  display: flex;
  flex-direction: column;
  gap: $spacing-5;

  &_userInfo {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-5;
    padding: $spacing-5;
    border-radius: $border-radius-base;
    box-shadow: rgba(0, 0, 0, 0.12) 0 6px 16px;
  }

  &_avatar {
    position: relative;
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;
  }

  &_report {
    display: none;
  }

  &_avatarArea {
    display: flex;
    gap: $spacing-5;
    flex-direction: column;
  }

  &_details {
    display: flex;
    padding: $spacing-3 0;
    flex-direction: column;
    gap: $spacing-4;
    min-width: 150px;
  }

  &_boxTitle {
    font-weight: 500;
    margin-bottom: $spacing-5;
    font-size: $font-size-l;
  }

  &_name {
    font-size: $font-size-xxl;
  }

  &_gender {
    font-size: $font-size-l;
  }

  &_verifications, &_verifications__isMobile {
    font-size: $font-size-base;
  }

  &_verifications__isMobile {
    width: 100%;
  }

  &_verifications {
    display: none;
  }

  &_message {
    padding: $spacing-5;
    border-radius: $border-radius-base;
    box-shadow: rgba(0, 0, 0, 0.12) 0 6px 16px;
  }

  &_aboutText {
    overflow-wrap: break-word;
  }
}


@include for-screen-from-480 {
  .phoneAuthPage {

  }
}

@include for-screen-from-568 {
  .phoneAuthPage {
    margin: $spacing-7 auto;
    padding: 0 $spacing-4;

    &_avatar {
      width: 210px;
      height: 210px;
      min-width: 210px;
      min-height: 210px;
    }

    &_userInfo {
      gap: $spacing-7;
      flex-wrap: nowrap;
    }

    &_verifications {
      display: block;
    }

    &_verifications__isMobile {
      display: none;
    }

    &_report {
      display: block;
    }

    &_report__isMobile {
      display: none;
    }
  }
}

