@import "@/styles/variables";
@import "@/styles/mixins";

.userReport {
  &_action {
    width: 100%;
    border: 1px solid $color-gray;
    border-radius: $border-radius-base;
    padding: $spacing-3 $spacing-4;
    font-size: $font-size-base;
    font-weight: 500;
    background: #fff;
    color: $color-gray-dark;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;

    &:hover {
      .listingDetailActions_actionInner {
        scale: 1.1;
      }
    }
  }

  &_actionInner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $spacing-2;
    scale: 1;
    line-height: 0;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: $font-size-s;
    font-weight: 600;
    animation: normal;
    transition:
            scale 0.1s ease-in-out,
            color 0.2s ease-in-out;
  }

  &_actionIcon {
    font-size: 20px;
  }
}
