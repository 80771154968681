@import '@/styles/variables.scss';
@import "@/styles/mixins.scss";

.listingFilter {
  position: relative;

  &_button {
    position: relative;
    padding: $spacing-1 $spacing-4;
    z-index: $z-index-dropdown-button-active;
    border: $border-width-base solid $color-gray;
    border-radius: $border-radius-base;
    font-size: $font-size-s;
    text-wrap: nowrap;
    width: max-content;
    color: $color-black;
    background-color: #fff;
    cursor: pointer;
    line-height: 22px;

    &:hover {
      border-color: $color-black;
    }

    &:disabled {
      cursor: default;
      color: $color-gray-dark;
    }

    &__isActive {
      border-color: $color-black;
    }
  }

  &_activeFiltersCount {
    position: absolute;
    background-color: $color-green-main;
    color: #fff;
    border-radius: $border-radius-base;
    padding: $spacing-1 $spacing-3;
    font-size: $font-size-s;
    line-height: $font-size-s;
    right: -11px;
    top: -9px;
  }
}

@include for-screen-from-768 {
  .listingFilter_button {
    padding: $spacing-3 $spacing-6;
    font-size: $font-size-base;
  }

  .listingFilter_activeFiltersCount {
    right: -5px;
    top: -5px;
  }
}
