@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.addressCheckPage {
  max-width: 1050px;
  margin: $spacing-4 auto;
  display: flex;
  flex-direction: column;
  gap: $spacing-6;

  &_box {
    width: 100%;
    padding: $spacing-6 $spacing-7;
    border: 1px solid $color-gray-light;
    border-radius: $border-radius-base;
    box-shadow: rgba(0, 0, 0, 0.12) 0 6px 16px;
  }

  &_listings {
    display: flex;
    flex-direction: column;
    gap: $spacing-5;
  }

  &_title {
    font-size: $font-size-xxxl;
  }

  &_subtitle {
    font-size: $font-size-l;
    color: $color-grey3;
  }

  &_listingsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: $spacing-4;
    border-radius: $border-radius-base;
  }
}
