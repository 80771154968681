@import '@/styles/variables';

.cookieAcceptPopup {
  position: fixed;
  bottom: 0;
  z-index: $z-index-page-popup;
  background: white;
  box-shadow: 0 -2px 13px 0 rgba(125,125,125,0.46);
  padding: $spacing-6;
  width: 100%;

  &_content {
    min-height: 80px;
    max-width: 866px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $spacing-4;
  }

  &_title {
    font-size: $font-size-xxl;
  }

  &_text {
    line-height: 24px;
  }

  &_accept {
    max-width: 120px;
    width: 100%;
  }
}
