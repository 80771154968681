@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.listingReportModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-6;

  &_title {
    font-size: $font-size-xxl;
    font-weight: 500;
  }

  &_reasonsList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }

  &_message {
    min-height: 130px;
    width: 100%;
    padding: $spacing-4;
    border-radius: $border-radius-base;
    border: 1px solid $color-gray;
    font-size: $font-size-s;
    font-family: inherit;
    resize: none;

    &::placeholder {
      color: $color-gray-dark;
      font-size: $font-size-s;
    }
  }

  &_sendMessage {
    width: 160px;
  }
}

@include for-screen-from-768 {
  .listingReportModal {
    width: 480px;
  }
}
