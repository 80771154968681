@import "@/styles/variables";
@import "@/styles/mixins";

.divider {
  position: relative;
  display: flex;
  align-items: center;

  &_line {
    height: 1px;
    width: 100%;
    background-color: $color-gray;
  }

  &_logo {
    margin: $spacing-5 0 auto;
    padding: 0 $spacing-6;
    background-color: #fff;
    z-index: 5;
  }
}

@include for-screen-from-768 {
  .divider {
    &_logo {
      margin: $spacing-6 auto;
    }
  }
}
