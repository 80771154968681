@import "@/styles/variables.scss";

.userEmailValidation {
  display: flex;
  flex-direction: column;
  gap: $spacing-7;
  align-items: flex-start;
  position: relative;
  width: 100%;
}
