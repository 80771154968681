@import "@/styles/variables";
@import "@/styles/mixins";

.validationCodeFrom {
  position: relative;

  &_countdown {
    margin-bottom: $spacing-5;

    &__isExpired {
      color: $color-red;
      font-size: $font-size-s;
    }
  }

  &_inputs {
    display: flex;
    gap: $spacing-4;
  }

  &_input {
    padding: $spacing-4;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-base;
    border: 1px solid $color-gray;
    font-size: $font-size-xxxl;
    text-align: center;
    caret-color: transparent;

    &:focus {
      outline: unset;
      border-color: $color-green-main;
      box-shadow: 0 0 6px 2px $color-green-main;
    }

    &:disabled,
    &[disabled] {
      pointer-events: none;
      border-color: $color-gray;
      background-color: $color-gray-light;
      color: hsl(0, 0%, 60%);
    }
  }

  &_input__isInvalid {
    border-color: $color-red;
  }

  &_invalidCode,
  &_loader {
    position: absolute;
    bottom: -$spacing-7;
    width: 100%;
  }

  &_loader {
    display: flex;
    justify-content: center;
  }

  &_invalidCode {
    text-align: center;
    margin-top: $spacing-4;
    color: $color-red;
    font-size: $font-size-s;
  }

  &_info {
    margin-top: $spacing-8;
  }

  &_resendCode {
    display: flex;
    gap: $spacing-2;
    align-items: center;
    justify-content: center;
    margin-bottom: $spacing-2;
  }

  &_resendButton {
    color: $color-green-main;
    font-weight: 500;
    cursor: pointer;
  }
}

@include for-screen-from-360 {
  .validationCodeFrom {
    &_input {
      width: 54px;
      height: 54px;
      font-size: $font-size-xxxl;
    }
  }
}

@include for-screen-from-480 {
  .validationCodeFrom {
    &_input {
      width: 76px;
      height: 76px;
      font-size: $font-size-xxxxl;
    }
  }
}
