@import '@/styles/variables';

.userDocumentUpload {
  display: flex;
  gap: $spacing-6;
  flex-direction: column;

  &_documentsForms {
    display: flex;
    gap: $spacing-5;
    flex-direction: column;
  }

  &_document {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: $spacing-3;
  }

  &_submitButton {
    margin: 0 auto;
  }
}
