@import '@/styles/variables.scss';
@import "@/styles/mixins.scss";

.listingsFilters {
  display: flex;
  align-items: center;
  gap: $spacing-4;
  position: sticky;
  top: 102px;
  margin-left: -$spacing-4;
  padding-top: $spacing-3;
  padding-bottom: $spacing-3;
  padding-left: $spacing-3;
  overflow-x: scroll;
  z-index: $z-index-listings-filters;
  background-color: #fff;
  border-bottom-right-radius: $border-radius-base;
  border-bottom-left-radius: $border-radius-base;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  // position switching need to avoid bug on mobile safari, reproduced only on mobile device
  &__isActive {
    position: static;
  }

  &_mapViewSwitch {
    position: fixed;
    bottom: $spacing-7;
    left: 50%;
    transform: translateX(-50%);
    z-index: $z-index-mobile-map-switch;
  }
}

@include for-screen-from-568 {
  .listingsFilters {
    margin-bottom: $spacing-5;
  }
}

@include for-screen-from-768 {
  .listingsFilters {
    top: 58px;
    padding-top: $spacing-5;
    padding-bottom: $spacing-4;
  }
}

@include for-screen-from-1024 {
  .listingsFilters {
    top: 70px;
    width: auto;
    overflow-x: initial;

    &_mapViewSwitch {
      display: none;
    }

    &__isActive {
      position: sticky;
    }
  }
}

