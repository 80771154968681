@import "@/styles/variables";
@import "@/styles/mixins";

.phoneSignUpForm {
  &_field {
    position: relative;
    padding: $spacing-5 0;
  }

  &_label {
    margin-bottom: $spacing-4;
  }

  &_recaptcha {
    position: relative;
    display: flex;
    justify-content: center;
    margin: $spacing-6 -6px $spacing-7;
  }

  &_termsPrivacy {
    margin-bottom: $spacing-6;
    color: $color-grey2;
    font-size: $font-size-s;
    font-weight: 500;
    text-align: center;
  }

  &_termsPrivacyLink {
    color: $color-green-main;
    text-decoration: underline;
  }

  &_error,
  &_recaptchaError {
    position: absolute;
    color: $color-red;
    font-size: $font-size-s;
  }

  &_error {
    bottom: -$spacing-3;
  }

  &_recaptchaError {
    bottom: -20px;
  }
}

@include for-screen-from-480 {
  .phoneSignUpForm {
    &_recaptcha {
      margin-right: 0;
      margin-left: 0;
    }
  }
}
