.skeleton {
  width: 100%;
  height: 100%;
  background:  linear-gradient(143deg, #f1f0f0, #c1c0c0, #f1f0f0);
  background-size: 200%;
  background-position: left;
  animation: load 1s ease-out 0s infinite normal;
}

@keyframes load {
  0%, 100%{
    background-position: right;
  }
  50%{
    background-position: left;
  }
}
